import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"
import ContactGlobal from "components/ContactGlobal"
import Partners from "components/Partners"
import Projects from "components/Projects"

import { Hero, How, Why, Grid } from "page_components/developer"

const Developers = () => {
  const title = "Dla deweloperów"
  return (
    <Layout
      seo={{
        title: title,
        description:
          "Tworzymy zewnętrzne biuro sprzedaży projektów deweloperskich. Naszych partnerów biznesowych – Deweloperów wspieramy już na wstępnych etapach inwestycji.",
      }}
    >
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      <Hero />
      <How />
      <Why />
      <Grid />
      <Partners />
      <Projects />
      <ContactGlobal header="Chcesz zwiększyć zyski ze swojego projektu?  " />
    </Layout>
  )
}

export default Developers
