import "./styles.scss"

import React from "react"

import Button from "components/Button"
import LazyFadeImage from "components/LazyFadeImage"

const Grid = () => {
  return (
    <section className="developer-grid">
      <div className="container-fluid">
        <h2>Jakie usługi oferujemy?</h2>

        <div className="row align-items-center">
          <div className="col-xl-5 col-md-6">
            <LazyFadeImage>
              <img
                src={
                  require("assets/images/developer/developer-grid-image-1.jpg")
                    .default
                }
                alt=""
              />
            </LazyFadeImage>
          </div>
          <div className="col-xl-5 offset-xl-1 col-md-6">
            <h3>Sprzedaż</h3>
            <h4>
              Zaplanowanie strategii sprzedażowej i aktywna sprzedaż projektów
            </h4>
            <p>
              Inwestorom towarzyszymy już na wstępnych fazach projektów.
              Zajmujemy się wczesną analizą, organizacją, marketingiem i
              sprzedażą projektów bez angażowania czasu dewelopera. Oszczędność
              zasobów inwestora przy maksymalizacji jego zysków.
            </p>
            <Button
              className="button--bordered"
              to="/dla-deweloperow/zaplanowanie-strategii-sprzedazowej-i-aktywna-sprzedaz-projektow/"
            >
              więcej
            </Button>
          </div>
        </div>

        <div className="row align-items-center">
          <div className="col-xl-4 offset-xl-1 col-md-6">
            <h3>Marketing</h3>
            <h4>Promocja projektu</h4>
            <p>
              Nasze działania opieramy na rynkowych trendach w dziedzinie
              marketingu i doświadczeniu w obszarze nieruchomości premium.
              Nieustannie monitorujemy efekty wdrożonych rozwiązań i
              dostosowujemy do zmieniającej się dynamiki sprzedaży czy sytuacji
              gospodarczej.
            </p>
            <Button
              className="button--bordered"
              to="/dla-deweloperow/doradztwo-oraz-stworzenie-strategii-marketingowej-i-promocji-projektu/"
            >
              więcej
            </Button>
          </div>
          <div className="col-xl-5 offset-xl-1 col-md-6">
            <LazyFadeImage>
              <img
                src={
                  require("assets/images/developer/developer-grid-image-2.jpg")
                    .default
                }
                alt=""
              />
            </LazyFadeImage>
          </div>
        </div>

        <div className="row align-items-center">
          <div className="col-xl-5 col-md-6">
            <LazyFadeImage>
              <img
                src={
                  require("assets/images/developer/developer-grid-image-3.jpg")
                    .default
                }
                alt=""
              />
            </LazyFadeImage>
          </div>
          <div className="col-xl-5 offset-xl-1 col-md-6">
            <h3>Wykończenie wnętrz i zarządzanie apartamentami</h3>
            <h4>Przygotowanie apartamentów do użytkowania.</h4>
            <p>
              Zwiększamy atrakcyjność inwestycji podejmując współpracę z
              najlepszymi specjalistami na rynku w zakresie wykończenia wnętrz i
              zarządzania apartamentami. Tym samym, oferowany produkt staje się
              dla Klienta bezobsługowy. Od chwili odebrania kluczy może czerpać
              on zyski z najmu bądź cieszyć się chwilami spędzonymi we własnym
              apartamencie.
            </p>
            <Button
              className="button--bordered"
              to="/dla-deweloperow/zarzadzanie-i-wykonczenie-apartamentu-w-inwestycji-deweloperskiej/"
            >
              więcej
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Grid
