import "./styles.scss"

import React from "react"

import LazyFadeImage from "components/LazyFadeImage"

const Hero = () => {
  return (
    <section className="developer-hero">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-xl-5 col-md-6">
            <h2>Zewnętrzne biuro sprzedaży projektów deweloperskich</h2>
            <p>
              Tworzymy zewnętrzne biuro sprzedaży projektów deweloperskich.
              Specjalizujemy się w segmencie nieruchomości premium. Naszych
              partnerów biznesowych – Deweloperówwspieramy już na wstępnych
              etapach inwestycji. Zapewniamy optymalizację projektu i
              dostosowanie go do oczekiwań odbiorców oraz konkurencyjność na
              rynku.
            </p>
            <p>
              Szyte na miarę działania marketingowe oraz zaplanowany i
              zrealizowany proces sprzedaży maksymalizuje sukces inwestycji.
              Wspólnie dążymy do celu oferując jednocześnie najwyższą jakość
              obsługi Klienta premium.
            </p>
          </div>
          <div className="col-xl-6 offset-xl-1 col-md-6">
            <LazyFadeImage>
              <div
                className="developer-hero__image"
                style={{
                  backgroundImage: `url('${
                    require("assets/images/developer/developer-hero-image.jpg")
                      .default
                  }')`,
                }}
              />
            </LazyFadeImage>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
