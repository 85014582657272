import "./styles.scss"

import React from "react"

import LazyFadeImage from "components/LazyFadeImage"

const Why = () => {
  const data = [
    {
      icon: require("assets/images/developer/developer-why-ico-1.svg").default,
      header: "Mniejsze koszty stałe",
      description:
        "Zminimalizujesz stałe koszty utrzymania biura, pracowników a także koszty działań marketingowych.",
    },
    {
      icon: require("assets/images/developer/developer-why-ico-2.svg").default,
      header: "Doświadczenie",
      description: "Obsługą procesu zajmą się doświadczeni eksperci z rynku.",
    },
    {
      icon: require("assets/images/developer/developer-why-ico-3.svg").default,
      header: "Oszczędność czasu",
      description:
        "Zaoszczędzisz czas przeznaczony na budowanie strategii sprzedażowej oraz marketingowej, a także na zapoznanie się ze specyfiką rynku oraz panujących trendów.",
    },
    {
      icon: require("assets/images/developer/developer-why-ico-4.svg").default,
      header: "Brak ograniczeń",
      description:
        "Przedstawimy projekt naszym Klientom, realizujemy projekty w zakresie sprzedaży na terenie całej Polski.",
    },
  ]

  return (
    <section className="developer-why">
      <div className="container-fluid">
        <h2>Dlaczego warto wybrać Marshall Real Estate?</h2>
        <div className="row">
          {data.map((item, index) => (
            <div className="col-lg-3 col-sm-6" key={index}>
              <div className="developer-why__item">
                <div className="developer-why__item-icon">
                  <LazyFadeImage>
                    <img src={item.icon} alt="" />
                  </LazyFadeImage>
                </div>
                <h4>{item.header}</h4>
                <p>{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Why
