import "./styles.scss"

import React from "react"

import LazyFadeImage from "components/LazyFadeImage"

const How = () => {
  const data = [
    {
      icon: require("assets/images/developer/developer-how-ico-1.svg").default,
      header: "Kwestie formalne",
      description: "Podpisanie jasnej i korzystnej umowy współpracy.",
    },
    {
      icon: require("assets/images/developer/developer-how-ico-2.svg").default,
      header: "Działania marketingowe",
      description: "Kampanie prowadzone na szerokim obszarze.",
    },
    {
      icon: require("assets/images/developer/developer-how-ico-3.svg").default,
      header: "Opieka nad klientami",
      description: "Od zapytania, przez wszystkie kwestie formalne.",
    },
    {
      icon: require("assets/images/developer/developer-how-ico-4.svg").default,
      header: "Finalizacja zakupu",
      description:
        "Ostatni najważniejszy etap zarówno dla nabywcy jak i dewelopera.",
    },
    {
      icon: require("assets/images/developer/developer-how-ico-5.svg").default,
      header: "Obsługa posprzedażowa",
      description:
        "Obsługa Klienta nie kończy się dla nas z dniem podpisania umowy na zakup.",
    },
  ]

  return (
    <section className="developer-how">
      <div className="container-fluid">
        <h2>Zobacz jak działamy</h2>
        <div className="row justify-content-center">
          {data.map((item, index) => (
            <div className="col-md" key={index}>
              <div
                className={`developer-how__item developer-how__item--${index}`}
              >
                <LazyFadeImage>
                  <img src={item.icon} alt="" />
                </LazyFadeImage>
                <h4>{item.header}</h4>
                <p>{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default How
